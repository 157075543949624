<template>
  <b-overlay
    :show="!loaded"
    style="min-height: 20vw; width: 100%"
    opacity="0.5"
  >
    <dashboard-kpi-admin v-if="$store.getters.state.user.permission_id === 1" />
    <dashboard-kpi-facilitator v-else-if="isFacilitator" />
    <dashboard-kpi-consortium v-else-if="isConsortium" />
    <dashboard-kpi-company
      v-else-if="$store.getters.state.user.permission_id === 3 || $store.getters.state.user.permission_id === 2"
    />
  </b-overlay>
</template>

<script>
import { get } from '../../services/api';
import DashboardKPIAdmin from './DashboardKPI/DashboardKPIAdmin.vue';
import DashboardKPICompany from './DashboardKPI/DashboardKPICompany.vue';
import DashboardKPIConsortium from './DashboardKPI/DashboardKPIConsortium.vue';
import DashboardKPIFacilitator from './DashboardKPI/DashboardKPIFacilitator.vue';

export default {
  name: 'DashboardHome',
  components: {
    'dashboard-kpi-admin': DashboardKPIAdmin,
    'dashboard-kpi-consortium': DashboardKPIConsortium,
    'dashboard-kpi-facilitator': DashboardKPIFacilitator,
    'dashboard-kpi-company': DashboardKPICompany,
  },
  data() {
    return {
      isFacilitator: false,
      isConsortium: false,
      loaded: false,
    };
  },
  async created() {
    /* if (this.$store.getters.state.user.permission_id !== 1) {
      this.$router.push({ name: 'quizzes' });
    } */
    try {
      await this.getConditionFacilitator();
      await this.getConditionConsortium();
      this.loaded = true;
    } catch (e) {
      this.loaded = true;
    }
  },
  methods: {
    async getConditionFacilitator() {
      await get(`facilitator/${this.$store.getters.state.user.id}`).then((response) => {
        if (parseInt(response.data.state, 10) === 2) {
          this.isFacilitator = true;
        }
      });
      return this.isFacilitator;
    },
    async getConditionConsortium() {
      await get(`consortium/${this.$store.getters.state.user.id}`).then((response) => {
        this.isConsortium = response.data.consortium;
      });
      return this.isConsortium;
    },
  },
};
</script>
